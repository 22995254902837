<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">考古发掘项目</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="XMMC"
                    label="项目名称"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.XMMC"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="XMBH"
                    label="项目编号"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.XMBH"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="DJCFJ"
                    label="第几次发掘"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.DJCFJ"
                        :disabled="!isEdit"
                        :maxlength="50"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="WWBMPZWH"
                    label="文物部门批准文号"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.WWBMPZWH"
                        :disabled="!isEdit"
                        :maxlength="50"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="PZFJMJ"
                    label="批准发掘面积(㎡)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.PZFJMJ"
                        :disabled="!isEdit"
                        @input="toNumber($event, 'PZFJMJ')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="KSSJ"
                    label="开始时间"
                    class="from-item-block"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.KSSJ"
                        :picker-options="pickerOptionsKSSJ"
                        type="date"
                        placeholder="选择开始日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="JSSJ"
                    label="结束时间"
                    class="from-item-block"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.JSSJ"
                        :picker-options="pickerOptionsJSSJ"
                        type="date"
                        placeholder="选择结束日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!enableEditDate"
                        @change="autoSaveOnChange"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="FJMJ"
                    label="发掘面积(㎡)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.FJMJ"
                        @input="toNumber($event, 'FJMJ')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="HTMJ"
                    label="回填面积(㎡)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.HTMJ"
                        @input="toNumber($event, 'HTMJ')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="ZYCZJF"
                    label="中央财政经费(万元)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.ZYCZJF"
                        @input="toDecimal($event, 'ZYCZJF')"
                        @blur="
                            blurMoney('ZYCZJF');
                            changeJFTRZE();
                        "
                        @focus="focusMoney('ZYCZJF')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="SHENGCZJF"
                    label="省财政经费(万元)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.SHENGCZJF"
                        @input="toDecimal($event, 'SHENGCZJF')"
                        @blur="
                            blurMoney('SHENGCZJF');
                            changeJFTRZE();
                        "
                        @focus="focusMoney('SHENGCZJF')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="SHICZJF"
                    label="市财政经费(万元)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.SHICZJF"
                        @input="toDecimal($event, 'SHICZJF')"
                        @blur="
                            blurMoney('SHICZJF');
                            changeJFTRZE();
                        "
                        @focus="focusMoney('SHICZJF')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="SYXCZJF"
                    label="市以下财政经费(万元)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.SYXCZJF"
                        @input="toDecimal($event, 'SYXCZJF')"
                        @blur="
                            blurMoney('SYXCZJF');
                            changeJFTRZE();
                        "
                        @focus="focusMoney('SYXCZJF')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="ZCJF"
                    label="自筹经费(万元)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.ZCJF"
                        @input="toDecimal($event, 'ZCJF')"
                        @blur="
                            blurMoney('ZCJF');
                            changeJFTRZE();
                        "
                        @focus="focusMoney('ZCJF')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="JFTRZE"
                    label="经费投入总额(万元)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.JFTRZE"
                        @input="toDecimal($event, 'JFTRZE', 9)"
                        @blur="blurMoney('JFTRZE', 9)"
                        @focus="focusMoney('JFTRZE')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="YYFJXCBHDJF"
                    label="用于发掘现场保护的经费(万元)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.YYFJXCBHDJF"
                        @input="toDecimal($event, 'YYFJXCBHDJF', 9)"
                        @blur="blurMoney('YYFJXCBHDJF', 9)"
                        @focus="focusMoney('YYFJXCBHDJF')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="已发表的简报" class="from-item-block">
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="reportList"
                        basicType="document"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>
                <el-form-item label="文物部门批准文件" class="from-item-block">
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="permitList"
                        basicType="document"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "kgfjxm",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1401",
                dataDic: {
                    glycbtid: this.heritageId,
                    XMMC: "",
                    XMBH: "",
                    DJCFJ: "",
                    WWBMPZWH: "",
                    PZFJMJ: "",
                    KSSJ: "",
                    JSSJ: "",
                    FJMJ: "",
                    HTMJ: "",
                    JFTRZE: "",
                    YYFJXCBHDJF: "",
                    ZYCZJF: "",
                    SHENGCZJF: "",
                    SHICZJF: "",
                    SYXCZJF: "",
                    ZCJF: "",
                },
            },
            rules: {
                XMMC: [
                    {
                        required: true,
                        message: "请填写项目名称",
                        trigger: "blur",
                    },
                ],
                XMBH: [
                    {
                        required: true,
                        message: "请填写项目编号",
                        trigger: "blur",
                    },
                ],
            },
            enumData: [],
            reportList: [],
            permitList: [],
            toMoneyProps: [
                "ZYCZJF",
                "SHENGCZJF",
                "SHICZJF",
                "SYXCZJF",
                "ZCJF",
                "JFTRZE",
                "YYFJXCBHDJF",
            ],
            pickerOptionsKSSJ: {
                disabledDate: (time) => {
                    let beginDateVal = new Date(this.formdata.dataDic.JSSJ);
                    if (beginDateVal) {
                        return time.getTime() > beginDateVal;
                    }
                },
            },
            pickerOptionsJSSJ: {
                disabledDate: (time) => {
                    let beginDateVal = new Date(this.formdata.dataDic.KSSJ);
                    if (beginDateVal) {
                        return time.getTime() < beginDateVal - 8.64e7;
                    }
                },
            },
        };
    },
    watch: {
        reportList: {
            deep: true,
            handler: function() {
                this.getFile();
            },
        },
        permitList: {
            deep: true,
            handler: function() {
                this.getFile();
            },
        },
    },
    mounted() {},
    methods: {
        getFile() {
            let listid = [],
                list = [];
            this.reportList.forEach((v) => {
                listid.push(`140101_${v.jcsjid}`);
                list.push(v);
            });
            this.permitList.forEach((v) => {
                listid.push(`140102_${v.jcsjid}`);
                list.push(v);
            });
            this.formdata.fileids = listid.join(",");
            this.formdata.metaJson = JSON.stringify(list);
        },
        changeJFTRZE() {
            let ZYCZJF =
                Number(
                    String(this.formdata.dataDic.ZYCZJF).replace(/,/g, "")
                ) || null;
            let SHENGCZJF =
                Number(
                    String(this.formdata.dataDic.SHENGCZJF).replace(/,/g, "")
                ) || null;
            let SHICZJF =
                Number(
                    String(this.formdata.dataDic.SHICZJF).replace(/,/g, "")
                ) || null;
            let SYXCZJF =
                Number(
                    String(this.formdata.dataDic.SYXCZJF).replace(/,/g, "")
                ) || null;
            let ZCJF =
                Number(String(this.formdata.dataDic.ZCJF).replace(/,/g, "")) ||
                null;
            let total = 0;
            if (ZYCZJF) {
                total = total + ZYCZJF;
            }
            if (SHENGCZJF) {
                total = total + SHENGCZJF;
            }
            if (SHICZJF) {
                total = total + SHICZJF;
            }
            if (SYXCZJF) {
                total = total + SYXCZJF;
            }
            if (ZCJF) {
                total = total + ZCJF;
            }
            this.formdata.dataDic.JFTRZE = total;
            this.blurMoney("JFTRZE", 9);
        },
        GetDataNext() {
            this.projectList = [];
            this.sytList = [];
            this.formdata.metaJson.forEach((v) => {
                if (v.fjlx == "140101") {
                    this.reportList.push(v);
                }
                if (v.fjlx == "140102") {
                    this.permitList.push(v);
                }
            });
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
